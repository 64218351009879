<template>
  <ConfirmDialog></ConfirmDialog>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Cajas</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3">Gastos Operativos - Costos</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
    >
      <div>
        <div class="font-medium text-3xl text-900">
          Administrar Servicios de Gastos Operativos - Costos
          <Badge size="xlarge" severity="success"> {{ totalRegistros }}</Badge>
        </div>
      </div>
      <div class="flex mt-3 lg:mt-0">
        <Button
          label="NUEVO SERVICIO"
          v-if="'Servicio Crear' in auth.user.permissions"
          class="p-button-outlined mr-2 p-button-lg"
          icon="pi pi-plus"
          v-tooltip.top="'Nuevo Servicio'"
          @click="nuevoServicio"
        >
        </Button>
      </div>
    </div>
    <div class="mt-4">
      <DataTable
        ref="dt_servicios"
        dataKey="id"
        :value="servicios"
        :paginator="true"
        :loading="cargando"
        :rows="50"
        :filters="buscar"
        :rowHover="true"
        class="p-datatable-sm"
        stripedRows
        showGridlines
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[50, 100, 200]"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Servicios"
        responsiveLayout="scroll"
        selectionMode="single click"
        @row-select="editarServicio($event.data)"
      >
        <template #header>
          <div
            class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
          >
            <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
              <i class="pi pi-search" />
              <InputText
                v-model="buscar['global'].value"
                placeholder="Buscar Servicio"
              />
            </span>
          </div>
        </template>
        <template #loading>
          <div class="flex align-items-center justify-content-center">
            <ProgressSpinner />
          </div>
        </template>
        <template #empty>
          <span class="flex align-items-center justify-content-center p-invalid"
            >No existen Registros!</span
          ></template
        >
        <Column header="#">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column>

        <Column field="id" header="CÓD." :sortable="true"></Column>
        <Column
          field="descripcion"
          header="DESCRIPCION/SERVICIO"
          style="font-weight: bold"
        ></Column>
        <Column field="created_at" header="F. CREACIÓN">
          <template #body="{ data }">
            {{ new Date(data.created_at).toLocaleDateString() }}
          </template>
        </Column>
        <Column field="updated_at" header="F. ACTUALIZACIÓN">
          <template #body="{ data }">
            {{ new Date(data.updated_at).toLocaleDateString() }}
          </template>
        </Column>
        <Column field="estado" header="ESTADO">
          <template #body="{ data }">
            <span :class="'product-badge ' + claseEstado[data.estado]">
              {{ data.estado_texto }}
            </span>
          </template>
        </Column>
        <Column header="ACCIONES">
          <template #body="slotProps">
            <Button
              class="p-button-icon-only p-button-raised"
              icon="pi pi-ellipsis-v"
              @click="$refs['menu' + slotProps.data.id].toggle($event)"
            />
            <Menu
              :ref="'menu' + slotProps.data.id"
              :popup="true"
              :model="acciones(slotProps.data)"
            >
            </Menu>
          </template>
        </Column>
      </DataTable>
      <ServicioCreate
        :show="servicioEditarDialog"
        :servicio="{ ...servicio }"
        @closeModal="cerrarModalServicio"
        @actualizarListado="cargarServicios"
      />
    </div>
  </div>
</template>
  
  <script>
import { FilterMatchMode } from "primevue/api";
import ServicioCreate from "@/module/servicios/ServicioCreate.vue";
import ServicioService from "@/service/ServicioService";

import { useAuth } from "@/stores";
export default {
  components: {
    ServicioCreate,
  },

  data() {
    return {
      cargando: true,
      servicios: null,
      servicioEditarDialog: false,
      nuevoServicioDialog: false,
      servicio: {},
      totalRegistros: 0,
      claseEstado: ["status-instock", "status-instock", " status-outofstock"],
      buscar: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
    };
  },
  servicioService: null,
  auth: null,
  created() {
    this.auth = useAuth();
    this.servicioService = new ServicioService();
  },
  mounted() {
    this.cargarServicios();
  },
  methods: {
    acciones(datos) {
      return [
        {
          label: "Editar Servicio",
          disabled:
            "Servicio Editar" in this.auth.user.permissions ? false : true,
          icon: "pi pi-fw pi-pencil",
          command: () => {
            this.editarServicio(datos);
          },
        },
        {
          label: "Eliminar",
          disabled:
            "Servicio Eliminar" in this.auth.user.permissions ? false : true,
          icon: "pi pi-fw pi-trash",
          command: () => {
            this.eliminarServicio(datos.id);
          },
        },
      ];
    },
    nuevoServicio() {
      this.servicioEditarDialog = true;
      this.servicio = {
        estado: { label: "ACTIVO", value: 1 },
      };
    },
    eliminarServicio(id) {
      this.$confirm.require({
        message: "¿Está seguro que desea eliminar el Servicio?",
        header: "Confirmación",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Si, deseo eliminar",
        rejectLabel: "No, cancelar",
        accept: () => {
          this.servicioService.eliminarServicio(id).then((response) => {
            if (response.status == 200) {
              this.$toast.add({
                severity: "success",
                summary: "Éxito",
                detail: response.mensaje,
                life: 3000,
              });
              this.cargarServicios();
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.mensaje,
                life: 3000,
              });
            }
          });
        },
      });
    },
    cargarServicios() {
      this.servicioService.getServiciosAll().then((data) => {
        this.totalRegistros = data.total || 0;
        this.servicios = data.servicios || [];
        this.cargando = false;
      });
    },
    editarServicio(servicio) {
      this.servicio = { ...servicio };
      this.servicio.estado = {
        label: servicio.estado_texto,
        value: servicio.estado,
      };
      this.servicioEditarDialog = true;
    },
    cerrarModalServicio() {
      this.servicioEditarDialog = false;
    },
  },
};
</script>
  <style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>  